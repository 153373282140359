import React,{useContext, useEffect,useState} from 'react';
import { useLocation } from 'react-router-dom'

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from '../Aera/PageHead';
import DonutChart from '../Aera/Home/DonutChart';

import Fractals from "./fractals.js";

import allFractals from "../../../images/allFractals.png";
import unbalance from "../../../images/unbalance.png";
import coinbag from "../../../images/coinbag.png";
import aeraUp from "../../../images/aeraUp.png";
import logo from "../../../images/logo.png";
import hermesLogo from "../../../images/maiaicon.png";

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {aeraAbi,nodeManagerAbi,usdcAbi,distributionManagerAbi,nftAbi} from '../../../data/abi';
import $ from "jquery";
import 
{providerOptions,aeraContract,nodeManagerContract,usdcContract,pairAddress,distributionManagerContract,renewalManagerContract,nftContract} 
from "../../../config";
import FractalsCounter from "./fractalsCounter.js";

const Home = ({pageName}) => {
	const { 
		changeBackground,changeNavigationHader,
		changePrimaryColor,chnageSidebarColor 
	}
	= useContext(ThemeContext);
	
	let provider;
	
	const web3Modal = new Web3Modal({
	  cacheProvider: true, // optional
	  providerOptions, // required
	  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});

//console.log(web3Modal);

let [totalFractals] = useState("");
let [totalA,setTotalA] = useState(0);
let [totalFractalsValue,setTotalFractalsValue] = useState(0);
let [totalUserFractals,setTotalUserFractals] = useState(0);
let [totalUserRewards,setTotalUserRewards] = useState(0);
let [totalMinted,setTotalMinted] = useState("");
let [totalMintedPercent,setTotalMintedPercent] = useState("");
let [mStyle,setmStyle] = useState("");

const roller=async()=>{
	if (web3Modal) {
		let dollarUSLocale = Intl.NumberFormat('en-US');
	
		if(web3Modal.cachedProvider=="injected"||web3Modal.cachedProvider=="walletconnect"){
	
	  provider = await web3Modal.connect();
	
	  const web3 = new Web3(provider);
	  const chainID = await web3.eth.getChainId();
	  if(chainID!=1088){
		return false;
	  }
	
	  let accounts = await web3.eth.getAccounts();
	  let selectedAccount = accounts[0];
	  let account = selectedAccount;
	  const walletAddress = account;
	  const walletAddress1 = walletAddress.substring(0, 4);
	  const walletAddress2 = walletAddress.substring(38);
	  const balance = await web3.eth.getBalance(account);
	
	  const ethBalance = web3.utils.fromWei(balance, "ether");
	  //console.log(ethBalance);
	  const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
	 $("#account").textContent = walletAddress1 + "..." + walletAddress2;
	 $("#account-balance").html(humanFriendlyBalance);
	//console.log(humanFriendlyBalance);
	  const aera = new web3.eth.Contract(
		aeraAbi,
		aeraContract
	)
	
	  const node = new web3.eth.Contract(
		nodeManagerAbi,
		nodeManagerContract
	  );
	
	  const usdc = new web3.eth.Contract(
		usdcAbi,
		usdcContract
	);

	const nft = new web3.eth.Contract(
		nftAbi,
		nftContract
	  );
	  
	
	const pair_usdc_balance = await usdc.methods.balanceOf(pairAddress).call();
	let balance1 = web3.utils.fromWei(pair_usdc_balance,"mwei");
	
	const pair_aera_balance = await aera.methods.balanceOf(pairAddress).call();
	let balance2 = web3.utils.fromWei(pair_aera_balance,"Ether");
	
	const treasuryAeraBalance = await aera.methods.balanceOf("0x433dC467dD8EF8c30c93C81829258804BC276167").call();
	
	const distributorAeraBalance = await aera.methods.balanceOf("0x169efdbB2194Ca00Ce159083954B43C8d6A8eC79").call();
	
	const aeraContractBalance = await aera.methods.balanceOf("0xFE540D6dbAD8C68928778AaF2Be828efA4b44Fa2").call();
	
	const circulatingSupply = 1000000 - parseFloat(web3.utils.fromWei(treasuryAeraBalance,"Ether")).toFixed(0) - parseFloat(web3.utils.fromWei(distributorAeraBalance,"Ether")).toFixed(0) - parseFloat(web3.utils.fromWei(aeraContractBalance,"Ether")).toFixed(0);
	
	//console.log(circulatingSupply);
	
	let aeraPrice = parseInt(balance1)/parseInt(balance2);
	
	$("#aera-price").html("<b>$"+parseFloat(aeraPrice).toFixed(2)+"</b>");
	
	//$("#circulating-supply").html(circulatingSupply);
	
	$("#aera-market-cap").html(dollarUSLocale.format(circulatingSupply)+"/$"+dollarUSLocale.format(parseFloat(circulatingSupply*aeraPrice).toFixed(0)));
	
	  await node.methods.totalNodesCreated().call((err, data) => {
	   //(data);
	   totalFractals = data;
	  
	   setTotalA(totalA=data);
	
	   setTotalFractalsValue(totalFractalsValue=data*aeraPrice);
	
	   $("#totalFractals").html(data+"/2500");
	
	   $("#totalFractalsValue").html("$"+dollarUSLocale.format(totalFractalsValue.toFixed(0)*10));
	  });
	
	  await aera.methods.balanceOf(account).call((err, data) => {
		
			$("#aera-balance").html(parseFloat(web3.utils.fromWei(data,"Ether")).toFixed(2) + " $AERA");
	
	});
	
	//start distribution timer
	
		const distri = new web3.eth.Contract(
			distributionManagerAbi,
			distributionManagerContract
		)
		
		distri.methods.getNextEpoch().call((err,data)=>{
			var s = new Date(parseInt(data)*1000);
	
			var ss = new Date(parseInt(data)).getTime();
	
			//console.log(s);
	
			let countDownDate1 = s.getUTCDay()+"/"+s.getUTCDate()+"/"+s.getUTCFullYear()+" "+s.getUTCHours()+":"+s.getUTCMinutes();
	
			var countDownDate = new Date(s).getTime();
	
			//console.log(countDownDate1);
	
				// Update the count down every 1 second
		 var x = setInterval(function() {
		 
			// Get today's date and time
			var now = new Date().getTime();
		  
			// Find the distance between now and the count down date
			var distance = countDownDate - now;
		  
			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
		  
			// Display the result in the element with id="demo"
			$("#next-reward").html(hours + "h "
			+ minutes + "m " + seconds + "s ");
		  
			// If the count down is finished, write some text
			if (distance < 0) {
			  clearInterval(x);
			  $("#next-reward").html("Distributing...");
			}
		  }, 1000);
		})
	
	//end distribution timer
	
	//start user fractals
	try {
		await node.methods._getNodesNames(account).call({ from:account},(err, data) => {
			if(!err){
			let nodes = data.split("#");
			var c = $("#nodes_list tbody");
			if(err){
				$("#userFractals").html("<h4>0</h4>");
			}else {
			setTotalUserFractals(totalUserFractals=nodes.length);
			$("#userFractals").html(nodes.length+" / $"+dollarUSLocale.format(nodes.length * 10 * aeraPrice.toFixed(0)));
			}
	
			if (nodes.length > 0) {
				c.html("");
				let no;
				for (let i = 0; i < nodes.length; i++) {
					//var s = new Date(parseInt(creationDate[i])*1000);
	
					no = i + 1;
	
					//let month = parseInt(s.getMonth())+1;
	
					/*c.append("<tr><td>"+no+"</td><td>" + nodes[i] + "</td><td>$"+dollarUSLocale.format(10*aeraPrice.toFixed(0))+"</td><td>"+s.getDate()+"-"+month+"-"+s.getFullYear()+"</td><td >"+reward[i]+"</td><td><a href='#' class='btn btn-sm btn-primary-3' data-toggle='modal' data-target='#transactionModal' onclick='cashoutReward("+creationDate[i]+")'>Claim Rewards</a></td></tr>");*/
				}
			} else {
				c.append("<tr><td colspan='2'>No Node purchased yet!</td></tr>");
			}
		}
		});
	
	}catch(error4){
	   // console.log(error4);
	}
	//end user fractals
	
	//start user rewards balance
	try {
		let rewardBalance
		await aera.methods.getRewardAmount().call({ from: account },(err, data1) => {
			if(data1==undefined){
				rewardBalance = 0;
			}else {
			 rewardBalance = web3.utils.fromWei(data1, "Ether");
			}
		  
			$("#rewardsBalance").html(parseFloat(rewardBalance).toFixed(2)+" $AERA / $"+dollarUSLocale.format(aeraPrice * parseFloat(rewardBalance).toFixed(2)));
		});
	}catch(error1){
		//console.log(error1);
	}
	//end user rewards balance
	
	await nft.methods.totalSupply().call((err,data)=>{
		console.log(data);
		let totalMintedPercentage = parseInt(data)/2500*100;
        setTotalMinted(data);
		//setmStyle('width:"'+totalMintedPercentage+'"');
		setTotalMintedPercent(Math.round(totalMintedPercentage,0));
	});

		}
	}
}

const autoConnect = async() =>{
	
  }


  useEffect(()=>{
roller();
 },[])

  useEffect(()=>{
     const roll = setInterval(()=>{roller()},60000);
	 return () => clearInterval(roll);
  },[])
  //autoConnect();


	return(
		<>
			<PageHead activePage="Dashboard"  pageName="Dashboard" />
			{/*<div className="row" >
				<div className="col-xl-12 col-xxl-12">
					<center><h1>Loading data.....</h1></center>
				</div>
	</div>*/}
			<div className="row">

			<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								{/*<h5>Balances</h5>*/}
								<table style={{color:"#FFF",border:"0px", borderStyle:"solid", borderRadius:"5px"}}>
									<tbody>
									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Metis Balance:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="account-balance"></span></td></tr>

									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Aera Balance:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="aera-balance"></span></td></tr>

									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Aera Price:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="aera-price"></span></td></tr>

									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Next Reward:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="next-reward"></span></td></tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body align-items-center justify-content-between">
							<div className="card-data me-2">

							<h5>Fractals Migration</h5>
							
							<div className="progress mb-4 bg-dark" style={{height:"18px",border:"1px solid #cfcfcf;", background:"#fff;"}}>
							<div className="progress-bar bg-primary progress-animated" style={{width:`${totalMintedPercent}%`,height:"19px"}} role="progressbar">
								<span className="sr-only">60% Complete</span>
							</div>
							</div>

							<table style={{color:"#FFF",border:"0px", borderStyle:"solid", borderRadius:"5px"}}>
									<tbody>
									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Unmigrated Fractal:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="">
									{2500-totalMinted}
										</span></td></tr>

									<tr><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}>Migrated Fractal:</td><td style={{border:"0px", borderStyle:"solid", padding:"3px",borderRadius:"5px"}}><span id="">
									{totalMinted}
										</span></td></tr>

									</tbody>
									</table>
								
							</div>
							
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								<h5>Total Fractals</h5>
								<h2 className="fs-40 font-w600">
									<span id="totalFractals" style={{color:"#facd1e"}}></span>
									</h2>
							</div>
							<DonutChart id="totalFractalsChart" value={totalA} totalCount="2500" backgroundColor="rgb(255, 135, 35)"
								backgroundColor2= "rgba(242, 246, 252)"
							/>
						</div>
					</div>
				</div>

			<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								<h5>All Fractals Value</h5>
								<h2 className="fs-40 font-w600"><span id="totalFractalsValue" style={{color:"#ff6445"}}></span></h2>
							</div>
					        <img src={allFractals} style={{width:"100px"}} />
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								<h5>Circulating Supply/Market Cap</h5>
								<h2 className="fs-40 font-w600"><span id="aera-market-cap" style={{color:"#2b8599"}}></span></h2>
							</div>
					
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								<h5>Total Fractal Value (FPO)</h5>
								<h6><span id="userFractals" style={{color:"#2b8599"}}></span></h6>
							</div>
							<img src={unbalance} style={{width:"100px"}} />
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6">
					<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
						<div className="card-body d-flex align-items-center justify-content-between">
							<div className="card-data me-2">
								<h5>My Rewards</h5>
								<h6 className="fs-40 font-w600"><span id="rewardsBalance" style={{color:"#2b8599"}}></span></h6>
							</div>
					        <img src={coinbag} style={{width:"100px"}} />
						</div>
					</div>
				</div>
				
			</div>	
			{/*
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div style={{float:"left",marginTop:"16px"}}>
					<h1>Aerarium Protocol APR(%)</h1>
					</div>
					<div style={{float:"left"}}>
					<img src={logo} style={{width:"80px",float:"left"}}/>
					</div>	 
				</div>
			</div>
			<hr style={{size:"0px",height:"0px"}}/>
<div className="row" style={{marginBottom:"50px"}}>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>$AERA APR(%)</h5>
					<h2 className="fs-40 font-w600"><span id="" style={{color:"#facd1e"}}>365%</span></h2>
				</div>
		
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>$HERMES APR(%)</h5>
					<h2 className="fs-40 font-w600"><span id="">Soon...</span></h2>
				</div>
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>$METIS APR(%)</h5>
					<h2 className="fs-40 font-w600"><span id="" style={{color:"#2b8599"}}>Soon...</span></h2>
				</div>
		
			</div>
		</div>
	</div>
	
</div>	
<div className="row">
				<div className="col-xl-12 col-xxl-12">
				<div className="col-xl-12 col-xxl-12">
					<div style={{float:"left",marginTop:"16px"}}>
					<h1>Hermes veNFT Growth Tracker (#980) </h1>
					</div>
					<div style={{float:"left"}}>
					<img src={hermesLogo} style={{width:"80px",marginLeft:"5px", float:"left"}}/>
					</div>	 
				</div>
					
				</div>
			</div>
			<hr style={{size:"0px",height:"0px"}}/>
			<div className="row">

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Starting Value</h5>
					<h3 className="fs-40 font-w600"><span id="">122,000 $HERMES</span></h3>
				</div>
		
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Current Value</h5>
					<h3 className="fs-40 font-w600"><span id="">613,721 $HERMES</span></h3>
				</div>
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Total Growth To Date</h5>
					<h3 className="fs-40 font-w600"><span id="" style={{color:"#facd1e"}}>502%</span></h3>
				</div>
		
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Projected weekly growth</h5>
					<h3 className="fs-40 font-w600"><span id="">Soon...</span></h3>
				</div>
		
			</div>
		</div>
	</div>
	
</div>
		*/}	
		</>
	)

}
export default Home;