import React from "react";

const ConnectButton = ({connected,buttonAction}) => {
    //console.log(buttonActionA);
    if(connected=="true"){
        return(
            <a className="btn btn-primary btn-block" href="#" onClick={buttonAction} style={{float:"right",width:"200px",background:"#2b8599",border:"0px"}}>Disconnect Wallet</a>
        )
    }else {
    return(
        <a className="btn btn-primary btn-block" href="#" onClick={buttonAction} style={{float:"right",width:"200px",background:"#2b8599", border:"0px"}}>Connect Wallet</a>
    )
    }
}

export default ConnectButton;