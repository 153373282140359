import React,{useContext, useEffect,useState} from 'react';
import { useLocation } from 'react-router-dom'

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from '../Aera/PageHead';
import netswapLogo from "../../../images/netswap.png";
import starstream from "../../../images/starstream.png";

const Bond = () => {
	const { 
		changeBackground,changeNavigationHader,
		changePrimaryColor,chnageSidebarColor 
	}
	= useContext(ThemeContext);

	


	return(
		<>
			<PageHead activePage="Farms"  pageName="Farms" />
			<hr style={{size:"0px",height:"1px", color:"#ff6445"}}/>
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div style={{float:"left",marginTop:"16px"}}>
					<h1></h1>
					</div>
					<div style={{float:"left"}}>
					<img src={starstream} style={{float:"left"}}/>
					</div>	 
				</div>
			</div>
			<hr style={{size:"0px",height:"0px"}}/>
<div className="row" style={{marginBottom:"50px"}}>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Liquidity Farm</h5>
					<a className="btn btn-primary btn-sm" href="https://starstream.finance/pools" target='_blank'  style={{width:"150px", marginTop:"10px", marginRight:"5px!important", marginBottom:"10px!important", background:"#ff6445",border:"#ff6445", borderRadius:"5px!important"}} >Open</a>
				</div>
		
			</div>
		</div>
	</div>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Autocompounding Vault</h5>
					<a className="btn btn-primary btn-sm" href="https://starstream.finance/vaults" target='_blank'  style={{width:"150px", marginTop:"10px", marginRight:"5px!important", marginBottom:"10px!important", background:"#ff6445",border:"#ff6445", borderRadius:"5px!important"}} >Open</a>
				</div>
			</div>
		</div>
	</div>
	
   </div>	
   <hr style={{size:"0px",height:"1px", color:"#ff6445"}}/>
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
				<div style={{float:"left"}}>
					<img src={netswapLogo} style={{width:"70px",float:"left"}}/>
					</div>	 
					<div style={{float:"left",marginTop:"16px"}}>
					<h1>Netswap (Soon...)</h1>
					</div>
					
				</div>
			</div>
			<hr style={{size:"0px",height:"0px"}}/>
<div className="row" style={{marginBottom:"50px"}}>

	<div className="col-xl-3 col-sm-6">
		<div className="card" style={{backgroundColor:"#0C0C0C", borderColor:"#ff6445", borderStyle:"solid", borderWidth:"1px"}}>
			<div className="card-body d-flex align-items-center justify-content-between">
				<div className="card-data me-2">
					<h5>Liquidity Farm</h5>
					<a className="btn btn-primary btn-sm" href="https://netswap.io/#/farm" target='_blank'  style={{width:"150px", marginTop:"10px", marginRight:"5px!important", marginBottom:"10px!important", background:"#ff6445",border:"#ff6445", borderRadius:"5px!important"}} >Open</a>
				</div>
		
			</div>
		</div>
	</div>
	
   </div>		
		</>
	)

}
export default Bond;